import { Table } from "react-bootstrap";
import AdminUserUrl from "./AdminUserUrl";



function AdminUserTable({participants}){

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>Участник</th>
                <th>Email</th>
                <th>URL</th>

                </tr>
            </thead>
            <tbody>
                {participants.map((user, id) => 
                <tr className={user.is_voted?"table-success":"table-warning"} key={id}>
                    <td>{id+1}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td><AdminUserUrl url={user.url}/></td>
                </tr>
                )}
                
                
            </tbody>
        </Table> 
    )

}

export default AdminUserTable;