import { Button, Dropdown, Form, FormControl, InputGroup } from "react-bootstrap";
import styles from './QuestionEditor.module.css'
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import {DropdownMenu, DropdownItem} from "react-bootstrap";
function QuestionEditor({questions, setQuestions}){
    const handleChangeQ = (id, text)=>{
        setQuestions(questions=>{
        const tmp = [...questions];
        tmp[id] = {...tmp[id], question: text}
        return tmp;
        })
    }
    const handleChangeC = (i, j, text)=>{
        setQuestions(questions=>{
            const tmp = [...questions];
            tmp[i].choices[j] = text;
            if (j === tmp[i].choices.length - 1 && text.trim() !== '')
                tmp[i].choices.push('');
            if (j!==0 && text.trim() === '')
                tmp[i].choices.splice(j, 1);
        return tmp;
        })
    }
    const handleAddQuestion = () => 
        setQuestions(q=>[...q, {question:'', choices: [''], type: 'question', min: 1, max: 1}]);
    const handleDeleteQuestion = idx => {
        if (idx===0) return;
        setQuestions(questions => {
            const tmp = [...questions];
            tmp.splice(idx, 1);
            return tmp;
        })
    }
    const handleChangeType = idx =>{
        const tmp = [...questions];
        tmp[idx].type = tmp[idx].type === "multiple"?"question":"multiple";
        if (tmp[idx].type === "question"){
            tmp[idx].max = 1;
            tmp[idx].min = 1;
        }
        setQuestions(tmp);
    }
    const handleMax = (idx, value) => {
        setQuestions(questions => {
            if (value < questions[idx].min) return questions;
            if (value > questions[idx].choices.length-1) return questions;
            const tmp = [...questions];
            tmp[idx].max = value;
            return tmp;
        })
    }
    const handleMin = (idx, value) => {
        setQuestions(questions => {
            if (value > questions[idx].max) return questions;
            const tmp = [...questions];
            tmp[idx].min = value;
            return tmp;
        })
    }
    const frmCN = `${styles.relative}`
    return (
<>
    <Form.Label><strong>Вопросы</strong></Form.Label>
    {questions.map((x,i)=>
    <Form.Group key={i} className={frmCN} controlId="formQuestion">
            <InputGroup className={x.type!=='multiple' && 'mb-2'}>
                <Form.Control autoComplete="off" type="text" placeholder="Текст вопроса..." value={x.q} onChange={e=>handleChangeQ(i, e.target.value)}/>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div style={{padding: "0.25rem 1rem"}}>
                        
                        <InputGroup>
                            <Button variant="light" className="btn-outline-secondary" onClick={()=>handleChangeType(i)}>Мультивыбор</Button>
                            <InputGroupText>от</InputGroupText>
                            <FormControl value={x.min} onChange={(e)=>handleMin(i, Number(e.target.value))} style={{maxWidth: '5rem'}} disabled={x.type !== 'multiple'} autoComplete="off" min={0} type="number"/>
                            <InputGroupText >до</InputGroupText>
                            <FormControl value={x.max} onChange={(e)=>handleMax(i, Number(e.target.value))} style={{maxWidth: '5rem'}} disabled={x.type !== 'multiple'} autoComplete="off" min={0} type="number"/>
                        </InputGroup>
                        </div>
                        <Dropdown.Divider/>
                        <Dropdown.Item href="#" onClick={()=>handleDeleteQuestion(i)}>Удалить</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {/* <Button  variant="dark" onClick={()=>handleDeleteQuestion(i)}>×</Button> */}
            </InputGroup>
            {
                x.type === 'multiple'&&
                <Form.Text>Мультивыбор от {x.min} до {x.max}</Form.Text>
            }
            <ul>
                {x.choices.map((choice, j)=>
                    <li key={j}>
                    <Form.Group className="mb-3" controlId="formQuestion">
                        <Form.Control autoComplete="off" type="text" placeholder="Вариант ответа..." value={choice} onChange={e=>handleChangeC(i, j, e.target.value)}/>
                    </Form.Group>
                    </li>
                )}
                
            </ul>
        </Form.Group>
        )}
        <Button 
            variant="secondary"
            type="button"
            onClick={handleAddQuestion}
        >
                Добавить вопрос
        </Button>
</>
    )
}

export default QuestionEditor;
