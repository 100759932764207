import ResultsBlock from "./ResultsBlock";



function UserFinished({data}){

    return (
        <>
            <h3>Голосование закончено</h3>
        </>
    )
}

export default UserFinished;