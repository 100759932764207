import { Button, Form, Table } from "react-bootstrap";
import styles from './ParticipantsTable.module.css'

function ParticipantsTable({participants, setParticipants}){
    const handleNameChange = (id, name) => {
        setParticipants(participants=>{
            const tmp = [...participants];
            tmp[id].name = name;
            return tmp;
        });
    };
    const handleEmailChange = (id, email) => {
        setParticipants(participants=>{
            const tmp = [...participants];
            tmp[id].email = email;
            return tmp;
        });
    };
    const deleteParticipant = id => {
        setParticipants(participants=>{
            const tmp = [...participants];
            tmp.splice(id, 1);
            return tmp;
        });
    }
    return (
<Table striped bordered hover>
    <thead>
        <tr>
            <th>#</th>
            <th>Участник</th>
            <th>Email</th>
        </tr>
    </thead>
    <tbody>
        {participants.map((user, id) => 
        <tr key={id}>
            <td>{id+1}</td>
            <td>
                
                <Form.Control 
                    type="text" placeholder="Имя" 
                    className={styles.transparent_input} 
                    value={user.name}
                    onInput={e=>handleNameChange(id, e.target.value)}
                />
            </td>
            <td className={styles.relative}>
                <Button
                    className={styles.delete_button} variant="danger"
                    tabIndex="-1"
                    onClick={()=>deleteParticipant(id)}
                >×</Button>
                <Form.Control
                    type="text" placeholder="Email"
                    className={styles.transparent_input} 
                    value={user.email}
                    onInput={e=>handleEmailChange(id, e.target.value)}
                />
            </td>

        </tr>)}
    </tbody>
</Table>
    )
}

export default ParticipantsTable;