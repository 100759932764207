


function TimeBlock({data}){
    
    return (
<>
    {!data.time_start? 
        <h4>Голосование ещё не начато</h4>:
        <>
            <h4>Начало: {data.starttime}</h4>
            {!data.time_end? 
                <h4>Голосование ещё не закончено</h4>:
                <h4>Конец {data.endtime}</h4>
            }
        </>
    }
    
</>
)}

export default TimeBlock;