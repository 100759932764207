import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import styles from './Header.module.css';

function Header(){
    return (
        <Navbar expand='lg' className={styles.navbar}>
            <Container>
                <Navbar.Brand href="#">Голосование СО РАН</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className={styles.navbar_collapse} id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/create">Создать голосование</Nav.Link>
            <Nav.Link disabled href="#my">Мои голосования</Nav.Link>
            <Nav.Link disabled href="#guide">Инструкция</Nav.Link>
          </Nav>
        </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;