import CreateFormWidget from "./widgets/CreateFormWidget/CreateFormWidget";

function CreateVotePage(){
    return(
        <>
            <h1>Создать опрос</h1>
            <CreateFormWidget/>
        </>
    )
}

export default CreateVotePage;