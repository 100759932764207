
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';

function QuestionEditor({questions, setQuestions}){
    const changeQ = (idx, text) => {
        const qs = [...questions];
        qs[idx] = {...qs[idx], q:text}; 
        setQuestions(qs);
    }
    const changeC = (i, j, choice) => {
        const qs = [...questions];
        qs[i].cs[j] = choice;
        if (j === qs[i].cs.length - 1 && choice.trim()!=="")
            qs[i].cs.push('');
        if (j !== 0 && choice.trim() === '')
            qs[i].cs.splice(j, 1);
        setQuestions(qs);
    }
    const addQ = () => {
        setQuestions([...questions, {q:'', cs: ['']}])
    }
    return (
        <>
        <Form.Label>Вопросы</Form.Label>
        {questions.map((x,i)=>
        <Form.Group key={i} className="mb-3" controlId="formQuestion">
            <Form.Control autoComplete="off"  className="mb-1" type="text" placeholder="Текст вопроса..." value={x.q} onChange={e=>changeQ(i, e.target.value)}/>
            <ul>
                {x.cs.map((choice, j)=>
                    <li key={j}>
                    <Form.Group className="mb-3" controlId="formQuestion">
                        <Form.Control autoComplete="off" type="text" placeholder="Вариант ответа..." value={choice} onChange={e=>changeC(i, j, e.target.value)}/>
                    </Form.Group>
                    </li>
                )}
                
            </ul>
        </Form.Group>
        )}
        <Button variant="secondary" type="button" onClick={addQ}>
                Добавить вопрос
            </Button>
        </>
    )
}

export default QuestionEditor