import { Button } from "react-bootstrap";
import AdminUserTable from "./features/AdminUserTable";
import TimeBlock from "./features/TimeBlock";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import BackendURLContext from "../../../contexts/BackendURLContext";
import getCookie from "../../../utils/getCookie";
import ResultsBlock from "./features/ResultsBlock";




function AdminVoteWidget({ data }) {
    const { poll_id, url } = useParams();
    const backend_url = useContext(BackendURLContext);
    const download_url = `${backend_url}/${poll_id}/${url}/download`
    const csrftoken = getCookie('csrftoken');

    const handleStart = () => {
        fetch(`${backend_url}/${poll_id}/${url}/start`, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({starttime: (new Date(Date.now())).toLocaleString('ru-ru')}),
            redirect: "follow",
            referrerPolicy: "no-referrer",
        }).then(x => {
            if (x.status === 200)    
                window.location.reload();
            else
                alert('Ошибка');
        }).catch(console.error);
    }
    const handleEnd = () => {
        fetch(`${backend_url}/${poll_id}/${url}/end`, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({endtime: (new Date(Date.now())).toLocaleString('ru-ru')}),
            redirect: "follow",
            referrerPolicy: "no-referrer",
        }).then(x => {
            if (x.status === 200)    
                window.location.reload();
            else
                alert('Ошибка');
        }).catch(console.error);
    }

    return (
        <>
            <h1>{data.title}</h1>
            <h2 className='mb-4'>Администрирование</h2>
            <Button disabled={data.time_start} className="mb-3" onClick={handleStart}>Начать голосование</Button>
            <Button disabled={data.time_end} className="mb-3 mx-3" onClick={handleEnd}>Закончить голосование</Button>
            <Button download href={download_url} className="mb-3 mx-3">Скачать список</Button>
            <AdminUserTable participants={data.participants} />
            <TimeBlock data={data} />
            <ResultsBlock data={data}/>
        </>
    )
}

export default AdminVoteWidget;