import ResultsBlock from "./features/ResultsBlock";
import TimeBlock from "./features/TimeBlock";
import UserFinished from "./features/UserFinished";
import UserNotStarted from "./features/UserNotStarted";
import UserPending from "./features/UserPending";


function GetStatus(data){
    const status = data.status.split('_');
    if (status.length < 2) return ()=><h2>Ошибка</h2>;
    if (status[1] === "NOTSTARTED") return ()=><UserNotStarted data={data}/>;
    if (status[1] === "FINISHED") return ()=><UserFinished data={data}/>;
    if (status[1] === "PENDING") return ()=><UserPending data={data}/>;

    return ()=><h1>Ошибка</h1>;
}


function UserVoteWidget({data}){

    const Widget = GetStatus(data);

    return (
        <>
            <h1>{data.title}</h1>
            <h3>{data.name}</h3>
            <hr/>
            <Widget/>
            <hr/>
            <TimeBlock data={data}/>
            <ResultsBlock data={data}/>
        </>
    )
}

export default UserVoteWidget;