import { Table } from "react-bootstrap";


function ResultsBlock({data}){


    return (
<>
    {!data.results?
    <h3>Результаты пока недоступны</h3>:
    <>
        <h3>Результаты</h3>
        {Object.keys(data.results).map(i=>
        <>
            <h3>{data.results[i].text}</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Вариант</th>
                    <th>Количество проголосовавших</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data.results[i].choices).map((j, id) => 
                    <tr key={id}>
                        <td>{data.results[i].choices[j].text}</td>
                        <td>{data.results[i].choices[j].count}</td>
                    </tr>
                    )}
                    
                </tbody>
            </Table>
        </>)}

    </>}
</>)
}

export default ResultsBlock;