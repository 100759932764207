

function parseParticipantsFile(text){
    text.replace('\r', '');
    const lines = text.split('\n');
    const users = lines.map(line => {
        const [name, email] = line.split(',');
        return {name, email};
    }).filter(user => user.name && user.email);
    
    return users;
}

export default parseParticipantsFile;